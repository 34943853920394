<section class="page d-flex flex-column justify-content-between h-100" style="overflow-y: scroll;">

    <div>

        <button matSuffix mat-icon-button (click)="back()" class="mb-3">
            <mat-icon>arrow_back</mat-icon>
        </button>

        <h2 translate>{{ title }}</h2>

    </div>

    <section class="mb-sm-3">

        <div class="border rounded">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <img src="assets/img/icon/ic_bank.svg" width="24" alt="Permission icon"/>
                <span class="d-block" translate>title.permission_bank</span>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-3">
                <img src="assets/img/icon/ic_user.svg" width="24" alt="Permission icon"/>
                <span class="d-block" translate>title.permission_credentials</span>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-3">
                <img src="assets/img/icon/ic_privacy.svg" width="24" alt="Permission icon"/>
                <span class="d-block" translate>title.permission_retire</span>

            </div>
            <div class="d-flex justify-content-between align-items-center">
                <img src="assets/img/icon/ic_chat.svg" width="24" alt="Permission icon"/>
                <span class="d-block" translate>title.data_consents</span>
            </div>
        </div>

    </section>

    <p class="txt-identificate-con" translate>title.powered_by_yoid_sp</p>

    <div>

        <yoid-button [text]="'actions.next' | translate" [loading]="loading"
            (onSubmit)="submit()"></yoid-button>
    
        <div style="margin-top: 10px;">
            <powered-by />
        </div>

    </div>

</section>