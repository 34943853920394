import { MatDialog } from '@angular/material/dialog';
import { MsgDialogComponent, MsgDialogData } from '../shared/msg-dialog/msg-dialog.component';
import { Constants } from 'src/app/shared/constants.shared';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { ConfirmDialogData, ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';

export class NotificationUtils {

    public static dialog(dialog: MatDialog, title: string, msg: string | undefined, btnText: string = "actions.accept", icon: string) {

        const data: MsgDialogData = {
            title,
            msg,
            btnText,
            icon
        };

        try {
            dialog.open(MsgDialogComponent, {
                data,
                restoreFocus: true,
                autoFocus: true,
            });
        } catch(e) { }

    }
    
    public static confirmDialog(dialog: MatDialog, title: string, msg: string, positive: string = "actions.accept", negative: string = "actions.cancel", callback: (res: boolean) => void) {

        const data: ConfirmDialogData = {
            title,
            msg,
            positiveBtnText: positive,
            negativeBtnText: negative,
        };

        dialog.open(ConfirmDialogComponent, {
            data,
            restoreFocus: true,
            autoFocus: true,
        }).afterClosed().subscribe(callback);

    }

    public static snackbarConfig(): MatSnackBarConfig<any> {
        return {
            duration: Constants.SNACKBAR_DURATION,
            verticalPosition: "top"
        }
    } 

}