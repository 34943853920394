<button *ngIf="!stroke" mat-raised-button (click)="submit()" [disabled]="disabled || loading" color="{{color}}" class="d-flex gap-3 w-100">
    <span *ngIf="!loading" class="d-block">{{ text }}</span>
    <div>
        <mat-spinner *ngIf="loading" style="height: 20px;"></mat-spinner>
    </div>
</button>
<button *ngIf="stroke" mat-stroked-button (click)="submit()" [disabled]="disabled || loading" color="{{color}}" class="d-flex gap-3 w-100">
    <span *ngIf="!loading" class="d-block">{{ text }}</span>
    <div>
        <mat-spinner *ngIf="loading" style="height: 20px;"></mat-spinner>
    </div>
</button>
