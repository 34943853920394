import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '../../shared/constants.shared';
import { IdDocType } from '../../interfaces/id-doc-type.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import ModelUtils from '../../utils/model.utils';
import { FormUtils } from '../../utils/form.utils';
import { SessionService } from '../../services/session.service';
import { IdentificationService } from '../../services/identification.service';
import { NotificationUtils } from '../../utils/notifications.utils';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'iddoc-page',
  templateUrl: './iddoc.component.html'
})
export class IddocComponent implements AfterViewInit {

  _valid: boolean = false;
  _loading: boolean = false;

  _idDocTypes: IdDocType[] | undefined = undefined;

  _selectedIdDocType: IdDocType | undefined = undefined;

  form: FormGroup = this.formBuilder.group({
    idDocNumber: ["", [Validators.required]], 
    idDocTypeId: [, [Validators.required]], 
  });

  @ViewChild("idDocInput", { static: false }) idDocInput: any;
  sessionForTesting: any;

  get idDocNumber() { return this.form.controls["idDocNumber"].value; }

  set idDocNumber(value: string) { this.form.controls["idDocNumber"].setValue(value); }

  set selectedIdDocType(selected: IdDocType | undefined) {
    this._selectedIdDocType = selected;
    if (selected) this.form.controls["idDocTypeId"].setValue(selected.id);
  }

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private session: SessionService,
    private formBuilder: FormBuilder,
    private identification: IdentificationService, 
  ) {}

  ngOnInit() {
    this.fetch();
    this.form.valueChanges.subscribe(_ => this.validate());
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if(this.idDocInput) this.idDocInput.nativeElement.focus();
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(_: any) {
    return false;
  }

  fetch() {
    
    this.identification.masters().subscribe({
      next: res => {

        this._idDocTypes = res.identityDocumentTypes;
 
        this.idDocNumber = this.session.idDoc ? this.session.idDoc : '';
        this.selectedIdDocType = this.session.idDocType ? this.session.idDocType : ModelUtils.getDefaultIdDocType(this._idDocTypes);

        this.validate();

      }
    });

  }

  validate() {
    if (this._selectedIdDocType) { 
      let valid = this.form.valid && FormUtils.validRegex(this._selectedIdDocType.validationRegex, this.idDocNumber);
      if (this._selectedIdDocType.id === Constants.IdDocType.DNI_ID) {
        valid = valid && FormUtils.validateDNI(this.idDocNumber);
      }
      this._valid = valid;
    }
  }

  submit(event: any) {

    if (event) event.preventDefault();

    if (!this.form.valid || !this._selectedIdDocType) return;

    this._loading = true;

    this.identification.sendIdDoc(this._selectedIdDocType, this.idDocNumber.toUpperCase()).subscribe({
      next: _ => this.router.navigate([Constants.Path.SELECT_BANK], { queryParams: this.session.operationIdQueryParam() })
    }).add(() => this._loading = false);

  }

  idDocTypeChange() {

    if (!this._idDocTypes) return;

    const id = this.form.controls["idDocTypeId"].value;
    const newDocType = this._idDocTypes.find(item => item.id === id);

    if (newDocType && this._selectedIdDocType && newDocType.id !== this._selectedIdDocType.id) {
      this.selectedIdDocType = this._idDocTypes.find(item => item.id === id);
      this.idDocNumber = "";
    }

  }

  back() {
    
    NotificationUtils.confirmDialog(this.dialog, "header.warning", "title.verify_phone_again_confirm", undefined, undefined, confirmed => {
      if (confirmed) {
        this.session.verifiedPhone = false;
        this.router.navigate([Constants.Path.PHONE]);
      }
    })

  }

}
