import { Country } from "../interfaces/country.interface";
import { IdDocType } from '../interfaces/id-doc-type.interface';

export default class ModelUtils {

    static getDefaultIdDocType(idDocTypes: IdDocType[]): IdDocType | undefined {
        return idDocTypes.find(c => c.default);
    }
    
    static getIdDocTypeById(idDocTypes: IdDocType[] | undefined, id: number): IdDocType | undefined {
        if (!idDocTypes) return undefined;
        return idDocTypes.find(c => c.id === id);
    }
    
    static getCountryById(countries: Country[] | undefined, id: number): Country | undefined {
        if (!countries) return undefined;
        return countries.find(c => c.id === id);
    }

    static getDefaultCountry(countries: Country[]): Country | undefined {
        return countries.find(c => c.default);
    }

} 