import {Component} from '@angular/core';
import {Constants} from "../../shared/constants.shared";
import {Router} from "@angular/router";
import {SessionService} from "../../services/session.service";
import {IdentificationService} from 'src/app/services/identification.service';
import {interval, Subscription} from 'rxjs';
import {Status, StatusRes} from 'src/app/interfaces/responses/status-res.interface';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-finalize-app',
  templateUrl: './finalize-app.component.html',
  styleUrls: ['./finalize-app.component.scss']
})
export class FinalizeAppComponent {

  loading: boolean = false;
  intervalSubscription: Subscription = new Subscription;
  errorMessage!: string;

  constructor(
    private router: Router,
    private session: SessionService,
    private translate: TranslateService,
    private identification: IdentificationService
  ) { }

  ngOnInit() {
    this.intervalSubscription = interval(10000).subscribe(() => {
      this.fetchStatusData();
    });
  }

  ngOnDestroy() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  fetchStatusData() {
    this.identification.status().subscribe({
      next: res => {
        switch (res.status) {
          case Status.Active:
            this.errorMessage = this.translate.instant("title.finalize_description.accepted");
            this.serviceProviderRedirect(res);
            break;
          case Status.Rejected:
            this.errorMessage = this.translate.instant("title.finalize_description.rejected");
            this.intervalSubscription.unsubscribe();
            this.serviceProviderRedirect(res);
            break;
          case Status.Expired:
            this.errorMessage = this.translate.instant("title.finalize_description.expired");
            this.intervalSubscription.unsubscribe();
            this.serviceProviderRedirect(res);
            break;
          case Status.Pending:
            break;
        }

      }
    });
  }

  serviceProviderRedirect(res: StatusRes){
    setTimeout(() => {
      window.location.href = res.redirect_url
    }, 5000);
  }

  continue() {
    this.identification.continueByWeb().subscribe({
      next: res => {
        this.router.navigate([Constants.Path.VERIFY_PHONE], { queryParams: this.session.operationIdQueryParam() });
      }
    });
  }

  back() {
    this.router.navigate([Constants.Path.PHONE], { queryParams: this.session.operationIdQueryParam() });
  }
}
