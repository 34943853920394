import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {Constants} from '../../shared/constants.shared';
import {TranslateService} from '@ngx-translate/core';
import {IdentificationService} from '../../services/identification.service';
import {SessionService} from 'src/app/services/session.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html'
})
export class PermissionsComponent {

  privacyPolicy?: SafeHtml;

  loading: boolean = false;

  get title() {
    if (!this.session.bank) {
      return "title.your_security_our_priority";
    } else {
      const title = this.translate.instant("title.will_identify_with");
      return title.replace(Constants.LOCALISE_REPLACE_STR, this.session.bank.name)
    }
  }

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private session: SessionService,
    private translate: TranslateService,
    private identification: IdentificationService
  ) { }

  ngOnInit() {
    this.identification.masters().subscribe(res => {
      const privacyText = this.translate.instant("title.permission_credentials").replace(Constants.LOCALISE_REPLACE_STR, res.privacyPolicy.url);
      this.privacyPolicy = this.sanitizer.bypassSecurityTrustHtml(privacyText);
    });
  }

  submit() {

    this.loading = true;

    this.identification.bankAuthorization(this.session.bank!.id).subscribe({
      next: res => window.location.replace(res.url)
      // next: res => window.location.href = res.url
    }).add(() => this.loading = false);

  }

  back() {
    this.router.navigate([Constants.Path.SELECT_BANK], { queryParams: this.session.operationIdQueryParam() });
  }

}
