import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { Constants } from 'src/app/shared/constants.shared';

@Component({
  selector: 'app-signature',
  templateUrl: './sign-steps.component.html',
  styleUrls: ['./sign-steps.component.scss']
})
export class SignStepsComponent {

  loading: boolean = false;

  constructor(
    private router: Router,
    private session: SessionService
  ) {}

  get message() {
    return this.session.privateMessage;
  }

  submit() {
    this.router.navigate([Constants.Path.DOCUMENT], { queryParams: this.session.operationIdQueryParam() });
  }

}
