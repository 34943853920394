import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'yoid-button',
  templateUrl: './yoid-button.component.html'
})
export class YoidButtonComponent {

  @Input() color: string = 'primary';
  @Input() text: string = "";

  @Input() stroke: boolean = false;

  @Input() loading: boolean = false;
  @Input() disabled: boolean = false;

  @Output() onSubmit = new EventEmitter<void>();

  submit() {
    this.onSubmit.emit();
  }

}
