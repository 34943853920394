import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from './services/session.service';
import { IdentificationService } from './services/identification.service';
import { Constants } from './shared/constants.shared';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalUtils } from './utils/global.utils';

import packageJson from '../../package.json';
import { environment } from 'src/environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { NotificationUtils } from './utils/notifications.utils';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  loading: boolean = true;

  initialized: boolean = false;

  get error() { return this.session.error; }

  get debug() { return environment.debug; }
  get version() { return packageJson.version; }

  constructor(
    private router: Router,
    private swUpdate: SwUpdate,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private session: SessionService,
    private translate: TranslateService,
    private identification: IdentificationService,
  ) { }

  ngOnInit() {

    this.translate.use("es-ES");

    if (this.insideIframe()) {
      this.session.error = Constants.ErrorTemplate.ERROR_NO_OPERATION;
      return;
    }

    this.updateCheck();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!this.session.operationId) {
          const operation = this.route.snapshot.queryParams["operationId"];
          if (!operation) {
            this.session.error = Constants.ErrorTemplate.ERROR_NO_OPERATION;
            return;
          }
          this.session.operationId = operation;
        }
        this.initialize();
      }
    });

  }

  initialize() {
    if (this.initialized) return;
    this.fetchConf(() => this.fetchMasters(() => this.getAccessToken()));
  }

  fetchConf(callback: () => void) {
    this.identification.configuration(this.session.operationId!).subscribe({
      next: res => {
        if (res.styleUrl) GlobalUtils.loadCss(document, res.styleUrl);
        if (res.faviconUrl) GlobalUtils.loadFavicon(res.faviconUrl);
        GlobalUtils.setTabTitle(this.translate.instant("actions.identify"));
        this.session.logo = res.logoUrl;
        callback();
      },
      error: _ => this.session.error = Constants.ErrorTemplate.ERROR_GENERIC
    });
  }

  fetchMasters(callback: () => void) {

    this.identification.masters().subscribe({
      next: _ => { callback() },
      error: _ => this.session.error = Constants.ErrorTemplate.ERROR_GENERIC
    }).add(() => this.loading = false);

    this.identification.allBankEntites().subscribe();

  }

  getAccessToken() {
    this.identification.initialize(this.session.operationId!).subscribe({
      next: _ => this.initialized = true,
      error: _ => this.session.error = Constants.ErrorTemplate.ERROR_GENERIC
    }).add(() => this.loading = false);
  }

  insideIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  updateCheck() {

    if (!this.swUpdate.isEnabled || !environment.debug) {
      return false;
    }

    this.swUpdate.checkForUpdate().then((updateAvailable: boolean) => {

      if (updateAvailable) {

        const snack = this.snackbar.open(
          this.translate.instant("title.new_version_msg"),
          this.translate.instant("actions.update"),
          NotificationUtils.snackbarConfig()
        );

        snack.onAction().subscribe(() => {
          window.location.reload();
        });

      }

    });

    return true;

  }


}
