import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface MsgDialogData {
  title: string, 
  msg?: string,
  icon: string,
  btnText: string,
}

@Component({
  selector: 'app-msg-dialog',
  templateUrl: './msg-dialog.component.html'
})
export class MsgDialogComponent {

  data?: MsgDialogData;

  constructor(
    private dialogRef: MatDialogRef<MsgDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: MsgDialogData
  ) {}

  ngOnInit() {
    this.data = this.dialogData;
  }

  dismiss() {
    this.dialogRef.close();
  }

}
