import { Injectable } from '@angular/core';
import { Country } from '../interfaces/country.interface';
import { IdDocType } from '../interfaces/id-doc-type.interface';
import { Bank } from '../interfaces/bank.interface';
import { SendOtpRes } from '../interfaces/responses/send-otp-res.interface';
import { VerifyOtpRes } from '../interfaces/responses/verify-otp-res.interface';
import { Constants } from '../shared/constants.shared';
import { Error } from '../interfaces/error.interface';
import { BehaviorSubject } from 'rxjs';
import { TemporalPhone } from '../interfaces/responses/temporal-phone-res.interface';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  token?: string;

  operationId?: string;

  logo?: string;

  readDoc: boolean = false;

  sentIdDoc: boolean = false;
  signedLegalTexts: boolean = false;

  phone?: string;
  country?: Country;

  sendOtpRes?: SendOtpRes;

  temporalPhone?: TemporalPhone;

  verifyOtpRes?: VerifyOtpRes;

  verifiedPhone: boolean = false;

  idDoc?: string;
  idDocType?: IdDocType;

  bank?: Bank;

  privateMessage?: string = "Nullam iaculis accumsan orci in porttitor. Quisque libero nisi, lacinia sed consectetur a, condimentum vitae nisl. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.";

  error?: Error;

  private userLanguage = new BehaviorSubject("ES");
  $userLanguage = this.userLanguage.asObservable();

  public operationIdQueryParam() {
    return this.operationId ? { "operationId": this.operationId } : {}
  }

  constructor() {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      this.setUserLanguage(savedLanguage);
    }
  }

  /*setUserLanguage(lang: string) {
    this.userLanguage.next(lang);
    localStorage.setItem('selectedLanguage', lang);
  }*/

  setUserLanguage(lang:string){this.userLanguage.next(lang);}

}
