<mat-dialog-content>

    <section class="p-4">
        <mat-form-field class="w-100">
            <mat-icon matPrefix>search</mat-icon>
            <input [(ngModel)]="input" matInput type="text" [placeholder]="'title.search_extension' | translate"
                autocomplete="off" autofocus>
            <button *ngIf="input" matSuffix mat-icon-button aria-label="Clear" (click)="input=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </section>

    <section class="p-4" style="height: 100%; overflow-y: scroll;">

        <h2 *ngIf="!input" class="text-black fs-2 px-3 pb-1 m-0" translate>title.most_used</h2>

        <div *ngIf="!input">
            <country-item *ngFor="let country of mostUsedCountries" [country]="country"
                [selected]="selected !== undefined && selected.id === country.id" (onSelect)="select(country)" />
        </div>

        <h2 *ngIf="!input" class="text-black fs-2 px-3 pt-4 pb-1" translate>title.all_countries</h2>

        <country-item *ngFor="let country of filteredCountries" [country]="country"
            [selected]="selected != undefined && selected.id === country.id" (onSelect)="select(country)" />

    </section>


</mat-dialog-content>
