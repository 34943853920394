<yoid-spinner *ngIf="loading && !operationData" />

<section *ngIf="!loading" class="page operation d-flex flex-column justify-content-between h-100">

    <section *ngIf="operationData">

        <h2 class="opacity-50 fs-1 mb-4" translate>title.sign_doc</h2>

        <div class="fields d-flex gap-3 flex-column border p-4">

            <h3 class="d-block fs-1 fw-bold d-block" style="margin-bottom: 0.25em;">{{ operationData.serviceProviderData.name }}</h3>

            <!-- NAME -->
            <mat-form-field *ngIf="operationData.shareName" class="w-100">
                <img src="assets/img/icon/ic_user.svg" width="24" matPrefix style="opacity: 0.5;" alt="Name icon"/>
                <mat-label translate>header.name</mat-label>
                <input type="text" [placeholder]="'header.name' | translate" [value]="operationData.name" matInput
                    disabled readonly>
            </mat-form-field>

            <!-- ID DOC -->
            <mat-form-field *ngIf="operationData.shareIdentityDocument" class="w-100">
                <img src="assets/img/icon/ic_identity.svg" width="24" matPrefix style="opacity: 0.5;" alt="Identity document icon"/>
                <mat-label>{{ operationData.identityDocumentType }}</mat-label>
                <input type="text" [value]="operationData.identityDocument" matInput disabled readonly>
            </mat-form-field>

            <!-- PHONE NUMBER -->
            <mat-form-field *ngIf="operationData.sharePhoneNumber" class="w-100">
                <img matPrefix src="assets/img/icon/ic_phone.svg" width="24" style="opacity: 0.5;" alt="Phone icon"/>
                <mat-label translate>header.phone_number</mat-label>
                <input type="text" [placeholder]="'header.phone_number' | translate" [value]="operationData.phoneNumber"
                matInput disabled readonly>
            </mat-form-field>
            
            <!-- DOCUMENT NAME -->
            <mat-form-field *ngIf="operationData.shareDocumentName" class="w-100">
                <img matPrefix src="assets/img/icon/ic_doc.svg" width="24" style="opacity: 0.5;" alt="Document icon"/>
                <mat-label translate>header.phone_number</mat-label>
                <input type="text" [placeholder]="'header.phone_number' | translate"
                    [value]="operationData.documentName" matInput disabled readonly>
            </mat-form-field>

        </div>

        <div *ngIf="privacyPolicy" class="mt-4">
            <a [href]="privacyPolicy" target="_blank" translate>title.signature policy</a>
        </div>

    </section>

    <section>

        <div class="d-flex justicy-content-between gap-4">
            <button mat-stroked-button [disabled]="loading" (click)="submit('accept')" color="primary" class="w-100">{{ "actions.reject" |
                translate
                }}</button>
            <yoid-button [loading]="loading" (onSubmit)="submit('accept')" [text]="'actions.accept' | translate"
                class="w-100"></yoid-button>
        </div>

        <div style="margin-top: 10px;">
            <powered-by />
        </div>

    </section>


</section>