<form class="page h-100 d-flex flex-column justify-content-between">

    <form [formGroup]="form" class="">

        <button matSuffix mat-icon-button (click)="back()" class="mb-3">
            <mat-icon>arrow_back</mat-icon>
        </button>

        <h2>{{ title }}</h2>

        <mat-form-field class="w-100 mt-4 mb-3">
            <input #otpInput formControlName="otp" type="phone" [placeholder]="'header.verification_code' | translate"
                autocomplete="off" inputmode="numeric" (keydown.enter)="submit($event)" matInput>
            <button *ngIf="otp" (click)="otp=''" matSuffix mat-icon-button aria-label="Clear">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <button (click)="resendOtp()" mat-button color="primary" class="text-decoration-underline">{{
            "title.resend_sms_code" | translate }}</button>

    </form>

    <div>

        <yoid-button [text]="'actions.next' | translate" [disabled]="!valid" [loading]="_loading"
            (onSubmit)="submit($event)"></yoid-button>

        <div style="margin-top: 10px;">
            <powered-by />
        </div>

    </div>

</form>