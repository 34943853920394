import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IddocComponent} from './pages/iddoc/iddoc.component';
import {PhoneComponent} from './pages/phone/phone.component';
import {VerifyPhoneComponent} from './pages/verify-phone/verify-phone.component';
import {IdentificationGuard} from './guards/identification.guard';
import {Constants} from './shared/constants.shared';
import {SelectBankComponent} from './pages/select-bank/select-bank.component';
import {PermissionsComponent} from './pages/permissions/permissions.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {IdentificationComponent} from './pages/operations/identification/identification.component';
import {FinalizeAppComponent} from "./pages/finalize-app/finalize-app.component";

const routes: Routes = [
  {
    path: Constants.Path.ROOT,
    pathMatch: "full",
    redirectTo: Constants.Path.PHONE
  },
  {
    path: Constants.Path.PHONE,
    component: PhoneComponent,
    canActivate: [IdentificationGuard]
  },
  {
    path: Constants.Path.FINALIZE_APP,
    component: FinalizeAppComponent,
    canActivate: [IdentificationGuard]
  },
  {
    path: Constants.Path.VERIFY_PHONE,
    component: VerifyPhoneComponent,
    canActivate: [IdentificationGuard]
  },
  {
    path: Constants.Path.IDENTITY_DOC,
    component: IddocComponent,
    canActivate: [IdentificationGuard]
  },
  {
    path: Constants.Path.SELECT_BANK,
    component: SelectBankComponent,
    canActivate: [IdentificationGuard]
  },
  {
    path: Constants.Path.PERMISSIONS,
    component: PermissionsComponent,
    canActivate: [IdentificationGuard]
  },
  // {
  //   path: Constants.Path.DOCUMENT,
  //   component: DocumentComponent
  // },
  // {
  //   path: Constants.Path.SIGNATURE_STEPS,
  //   component: SignStepsComponent
  // },
  {
    path: Constants.Path.OPERATION,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: Constants.Path.IDENTIFICATION,
      },
      {
        path: Constants.Path.IDENTIFICATION,
        component: IdentificationComponent
      },
      // {
      //   path: Constants.Path.SIGNATURE,
      //   component: SignatureComponent
      // },
    ]
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
