import { FormGroup } from '@angular/forms';
export class FormUtils {

    private static DNI_LETTERS = "TRWAGMYFPDXBNJZSQVHLCKE";

    static enableForm(form: FormGroup, value: boolean) {
        for (var control in form.controls) {
            if (value) form.controls[control].enable();
            else form.controls[control].disable();
        }
        return form;
    }

    static validRegex(pattern: string, str: string) {
        return new RegExp(pattern).test(str);
    }

    static validateDNI(dni: string): boolean {
        const dniNumber = parseInt(dni.slice(0, -1));
        const letter = dni.slice(-1).toUpperCase();
        const expectedLetter = FormUtils.DNI_LETTERS.charAt(dniNumber % 23);
        return letter === expectedLetter;
    }

}