import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Constants } from '../shared/constants.shared';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class IdentificationGuard implements CanActivate {

  constructor(
    private router: Router,
    private session: SessionService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!route.routeConfig?.path) return true;

    if (!this.session.operationId && route.queryParams["operationId"]) {
      this.session.operationId = route.queryParams["operationId"];
    }

    switch (route.routeConfig.path) {
      case Constants.Path.VERIFY_PHONE:
        if (this.session.phone == undefined || this.session.country == undefined) {
          return this.handle(false, Constants.Path.PHONE);
        } else {
          return this.handle(!this.session.verifiedPhone, Constants.Path.PHONE);
        }
      case Constants.Path.IDENTITY_DOC:
        return this.handle(this.session.verifiedPhone, Constants.Path.VERIFY_PHONE);
      case Constants.Path.SELECT_BANK:
        return this.handle(this.session.idDocType != undefined && this.session.idDoc != undefined, Constants.Path.IDENTITY_DOC);
      case Constants.Path.PERMISSIONS:
        return this.handle(this.session.bank != undefined, Constants.Path.IDENTITY_DOC);
      default:
        return true;
    }

  }

  handle(condition: boolean, redirect: string) {
    if (!condition) this.router.navigate([redirect], { queryParams: this.session.operationIdQueryParam() });
    return condition;
  }

}
