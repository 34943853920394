import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-document-dialog',
  templateUrl: './document-dialog.component.html',
  styleUrls: ['./document-dialog.component.scss']
})
export class DocumentDialogComponent {

  page: number = 1;
  logo: string = "";
  document: string = "";

  constructor(
    public dialogRef: MatDialogRef<DocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { logo: string, document: string, page: number }
  ) { this.initialize(data) }

  initialize(data: any) {

    this.page = data.page;
    this.logo = data.logo;
    this.document = data.document;

    this.dialogRef.afterClosed().subscribe(() => this.close());

  }

  change(page: number) {
    this.page = page;
  }

  close() {
    this.dialogRef.close(this.page);
  }

}
