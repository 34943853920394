<section *ngIf="_idDocTypes" class="page h-100">

    <form [formGroup]="form" class="d-flex flex-column justify-content-between h-100">

        <div>

            <button matSuffix mat-icon-button (click)="back()" class="mb-3">
                <mat-icon>arrow_back</mat-icon>
            </button>

            <h2 translate>title.document_number</h2>

            <div class="d-flex align-items-center gap-lg-4 gap-2">

                <mat-form-field appearance="fill">
                    <mat-select formControlName="idDocTypeId" [value]="_selectedIdDocType ? _selectedIdDocType.id : ''"
                        (selectionChange)="idDocTypeChange()">
                        <mat-option *ngFor="let idDocType of _idDocTypes" [value]="idDocType.id">
                            {{ idDocType.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="w-100 my-5">
                    <input #idDocInput formControlName="idDocNumber" name="phone" type="phone"
                        [placeholder]="_selectedIdDocType ? _selectedIdDocType.placeholder : ''" autocomplete="off"
                        (keydown.enter)="submit($event)" matInput>
                    <button *ngIf="idDocNumber.length > 0" (click)="idDocNumber=''" matSuffix mat-icon-button
                        aria-label="Clear">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

            </div>
        </div>

        <div>

            <yoid-button [text]="'actions.next' | translate" [disabled]="!_valid" [loading]="_loading"
                (onSubmit)="submit($event)"></yoid-button>

            <div style="margin-top: 10px;">
                <powered-by />
            </div>    

        </div>

    </form>

</section>