import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YoidButtonComponent } from './yoid-button/yoid-button.component';
import { YoidLanguageSelectorComponent } from './yoid-language-selector/yoid-language-selector.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MsgDialogComponent } from './msg-dialog/msg-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PoweredByComponent } from './powered-by/powered-by.component';
import { CountryDialogComponent } from './country-dialog/country-dialog.component';
import { CountryItemComponent } from './country-dialog/country-item/country-item.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { YoidSpinnerComponent } from './yoid-spinner/yoid-spinner.component';



@NgModule({
  declarations: [
    YoidButtonComponent,
    MsgDialogComponent,
    ConfirmDialogComponent,
    PoweredByComponent,
    CountryDialogComponent,
    CountryItemComponent,
    YoidSpinnerComponent
  ],
  imports: [
    MatIconModule,
    MatInputModule,
    FormsModule,
    CommonModule,
    MatButtonModule,
    YoidLanguageSelectorComponent,
    MatDialogModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    TranslateModule.forChild({
      extend: true
    }),
  ],
  exports: [
    PoweredByComponent,
    YoidButtonComponent,
    YoidLanguageSelectorComponent,
    MsgDialogComponent,
    CountryDialogComponent,
    YoidSpinnerComponent
  ]
})
export class SharedModule {}
