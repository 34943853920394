import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface ConfirmDialogData {
  title: string, 
  msg: string,
  positiveBtnText: string,
  negativeBtnText: string,
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {

  data?: ConfirmDialogData; 

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: ConfirmDialogData,
    public dialog: MatDialogRef<ConfirmDialogComponent>
  ) { }

  ngOnInit() {
    this.data = this.dialogData;
  }

  submit(value: boolean) {
    this.dialog.close(value);
  }

}

