import { HttpHeaders } from "@angular/common/http"

export class ApiUtils {

    public static getHeaders() {
        return new HttpHeaders({
            "Accept-Language": "es"
        });
    }

    public static getAuthHeaders(token: string | undefined) {
        return new HttpHeaders({
            "Accept-Language": "es",
            "Authorization": `Bearer ${token}` 
        });
    }

}