<section class="h-100" style="overflow-y: scroll;">

    <div class="page h-100 d-flex gap-3 flex-column justify-content-between">

        <div class="d-flex gap-3 justify-content-between align-items-center">
            <div class="d-flex align-items-center" style="width: 100px; height: 50px; object-fit: contain;">
                <img *ngIf="logo" [src]="logo" class="img-fluid" alt="">
            </div>
            <button mat-fab matRipple (click)="fullscreen()" class="zoom">
                <mat-icon>open_in_full</mat-icon>
            </button>
        </div>

        <div class="border rounded w-100 h-100 my-4 overflow-hidden">
    
            <pdf-viewer
                [src]="document"
                [page]="page"
                [show-all]="true"
                [original-size]="false"
                [autoresize]="true"
                [fit-to-page]="true"
                (pageChange)="pageChange($event)"
                (after-load-complete)="afterLoadComplete($event)"
                class="pdf-viewer">
            </pdf-viewer>
            
            <button mat-fab [disabled]="page === totalPages" (click)="scroll()" class="scroll-to-bottom">
                <img src="assets/img/icon/ic_arrow_down.svg" width="30" alt="Arrow down">
            </button>
             
        </div>
    
        <div [formGroup]="form">

            <section *ngIf="popup" class="d-flex align-items-center gap-lg-4 gap-2">

                <div class="d-flex flex-column">
                    <mat-form-field (click)="extensions()" style="width: 100px;">
                        <input #countryToggle type="text"
                            [value]="selected ? selected.phoneCode : ''"
                            style="text-align: left !important; cursor: pointer;" 
                            class="country-toggle" matInput readonly>
                    </mat-form-field>
                </div>
        
                <mat-form-field class="w-100">
        
                    <input name="phone" formControlName="phone" type="phone"
                        [placeholder]="'header.phone_number' | translate" autocomplete="off" inputmode="tel" matInput>
                    <button matSuffix mat-icon-button (click)="clearPhone()" aria-label="Clear">
                        <mat-icon>close</mat-icon>
                    </button>
        
                </mat-form-field>
        
            </section>
            
            <div class="d-flex align-items-center gap-lg-3">
                <mat-checkbox formControlName="termsAndConditions" [indeterminate]="false" />
                <span [innerHTML]="termsAndConditions"></span>
            </div>
    
            <div class="d-flex align-items-center gap-lg-3 mb-4">
                <mat-checkbox formControlName="privacyPolicy" [indeterminate]="false" />
                <span [innerHTML]="privacyPolicy"></span>
            </div>
    
        </div> 
    
        <div>
    
            <yoid-button [text]="'actions.next' | translate" [disabled]="form.invalid" [loading]="loading"
                (onSubmit)="submit()"></yoid-button>
        
            <div style="margin-top: 10px;">
                <powered-by />
            </div>
    
        </div>

    </div>
    
</section>
