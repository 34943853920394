<section class="page d-flex flex-column justify-content-between h-100" style="overflow-y: scroll;">

    <section class="d-flex flex-column gap-3 mb-sm-3">
        
        <h2 class="mb-4" translate>title.how_to_sign</h2>
        
        <div class="d-flex flex-column gap-2 justify-content-between mb-3">
            <span class="d-block fw-bold" translate>title.review_doc_1</span>
            <span class="d-block" translate>title.review_doc_1_description</span>
        </div>
        
        <div class="d-flex flex-column gap-2 justify-content-between mb-3">
            <span class="d-block fw-bold" translate>title.legal_text_2</span>
            <span class="d-block" translate>title.legal_text_2_description</span>
        </div>
        
        <div class="d-flex flex-column gap-2 justify-content-between mb-3">
            <span class="d-block fw-bold" translate>title.doc_signature_3</span>
            <span class="d-block" translate>title.doc_signature_3_description</span>
        </div>
        
        <div *ngIf="message" class="message border rounded p-3 mb-sm-5">
            <small class="d-block fw-bold" translate>title.private_msg</small>
            <span class="d-block text-break m-0">{{ message }}</span>
        </div>

    </section>


    <div>

        <yoid-button [text]="'actions.next' | translate" [loading]="loading"
            (onSubmit)="submit()"></yoid-button>
    
        <div style="margin-top: 10px;">
            <powered-by />
        </div>

    </div>

</section>