<div *ngIf="data" class="d-flex flex-column align-items-center justify-content-center text-center p-5">

    <img [src]="data.icon" class="mb-4" alt="Message icon">

    <h2 [class]="data.msg ? 'text-center' : 'text-center mb-4'">{{ data.title | translate }}</h2>

    <p *ngIf="data.msg" class="mb-4">{{ data.msg | translate }}</p>

    <yoid-button [text]="data.btnText | translate" (onSubmit)="dismiss()" class="w-100"></yoid-button>

</div>