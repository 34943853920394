import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Country } from 'src/app/interfaces/country.interface';
import { DocumentDialogComponent } from 'src/app/pages/document/document-dialog/document-dialog.component';
import { IdentificationService } from 'src/app/services/identification.service';
import ModelUtils from 'src/app/utils/model.utils';

@Component({
  selector: 'country-dialog',
  templateUrl: './country-dialog.component.html',
  styleUrls: ['./country-dialog.component.scss']
})
export class CountryDialogComponent {

  input: string = "";

  selected?: Country;
  countries: Country[] = [];

  get default() {
    return ModelUtils.getDefaultCountry(this.countries);
  }

  get mostUsedCountries() {
    return this.countries.filter(country => country.order).sort((a, b) => (a.order > b.order) ? 1 : -1);;
  }

  get filteredCountries() {
    if (!this.input) return this.countries;
    return this.countries.filter(country => {
      const name = country.name.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const input = this.input.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return name.includes(input);
    });
  }

  constructor(
    private identification: IdentificationService,
    public dialogRef: MatDialogRef<DocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { selected: number }
  ) {}

  ngOnInit() {
    this.identification.masters()
      .subscribe(res => {
        this.countries = res.countries;
        this.selected = this.countries.find(c => c.id === this.data.selected);
      });
  }

  select(country: Country) {
    this.selected = country;
    this.dialogRef.close(country);
  }

}
