import { Component } from '@angular/core';
import { Error } from 'src/app/interfaces/error.interface';
import { SessionService } from 'src/app/services/session.service';
import { Constants } from 'src/app/shared/constants.shared';

@Component({
  selector: 'error',
  templateUrl: './error.component.html'
})
export class ErrorComponent {

  error: Error;

  constructor(private session: SessionService) {
    this.error = this.session.error ? this.session.error : Constants.ErrorTemplate.ERROR_GENERIC;
  }

}
