<div class="container d-flex flex-column justify-content-center align-items-center h-100">

    <div *ngIf="debug" class="version fs-4">{{ version }}</div>

    <main>

        <router-outlet *ngIf="!loading && !error"></router-outlet>

        <yoid-spinner *ngIf="loading && !error" />

        <error *ngIf="error"></error>

    </main>

</div>