<section class="page d-flex flex-column justify-content-between h-100" style="overflow-y: scroll;">
  <div>
    <button matSuffix mat-icon-button (click)="back()" class="mb-3">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <h2 translate>title.finalize_app</h2>
  </div>

  <section class="mb-sm-3">
    <div *ngIf="!errorMessage"  class="d-flex justify-content-between align-items-center mb-3">
      <span class="d-block" translate> title.finalize_description.0 </span>
    </div>

    <div *ngIf="errorMessage"  class="d-flex justify-content-between align-items-center mb-3">
      <span lass="d-block">{{errorMessage}}</span>
    </div>
  

  </section>

  <div>
    <div *ngIf="!errorMessage"  class="d-flex d-inline-block gap-3 w-100">
      <button mat-button (click)="continue()">{{"actions.finalize_by_web" | translate }}</button>
    </div>
    <div style="margin-top: 10px;">
      <powered-by/>
    </div>

  </div>

</section>
