import {Component, EventEmitter, OnInit, Output, inject, OnDestroy} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { SessionService } from '../../services/session.service';
import { environment } from 'src/environments/environment';
import { ApiUtils } from '../../utils/api.utils';

@Component({
  selector: 'yoid-language-selector',
  templateUrl: 'yoid-language-selector.component.html',
  standalone: true,
  imports: [
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    CommonModule,
  ]
})


export class YoidLanguageSelectorComponent implements OnInit, OnDestroy{
  options: { value: string, label: string }[] = [];
  disableSelect = new FormControl(false);

  selectedLang:string  = 'es-ES';
  selectedLangLabel:string = 'ES';
  datos: { [key: string]: string } = {};


  @Output() onRefreshLanguage = new EventEmitter<string>();
  private session= inject(SessionService)

  subscription:Subscription;

  get authHeaders() { return ApiUtils.getAuthHeaders(this.session.token); }

  get headers() { return ApiUtils.getHeaders(); }

  constructor(private translate: TranslateService, private http: HttpClient) {
    this.subscription = this.session.$userLanguage.subscribe(data=>{
      this.selectedLangLabel = data;
      this.selectedLang = this.obtenerClave(data) || "es-ES";
    })
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadData() {
    this.session.$userLanguage.subscribe(language=>{
      this.selectedLangLabel = language.toUpperCase();
    });

    this.http.get('assets/i18n/locale.properties', { responseType: 'text' }).subscribe({
      next:(data: string) => {
        this.options = this.parseProperties(data);
        this.parseData(data);
      },
      error:(error) => {
        console.error('Error al cargar las propiedades', error);
      }
    });
  }

  parseData(data: string): void {
    const lines = data.split('\n');

    lines.forEach(line => {
      const [clave, valor] = line.split('=');
      if (clave && valor) {
        this.datos[clave.trim()] = valor.trim();
      }
    });
    this.selectedLang = this.obtenerClave(this.selectedLangLabel) || 'es-ES';
  }

  obtenerValor(clave: string): string | undefined {
    return this.datos[clave];
  }

  private obtenerClave(valor: string): string | undefined {
    return Object.keys(this.datos).find(clave => this.datos[clave] === valor.toUpperCase());
  }

  private parseProperties(data: string): { value: string, label: string }[] {
    return data.split('\n')
      .filter(line => line.trim() !== '')
      .map(line => {
        const [value, label] = line.split('=').map(part => part.trim());
        return { value, label };
      });
  }

  onLanguageChange(){
    this.translate.use(this.selectedLang);
    this.onRefreshLanguage.emit(this.selectedLang);
    this.selectedLangLabel = this.obtenerValor(this.selectedLang) ||'ES';
    //Guardar idioma en Background
    this.saveLanguage();
  }

  saveLanguage(){
    const body = { languageCode: this.selectedLangLabel, operationId: this.session.operationId }
    const url = `${environment.apiBaseUrl}/operation/bank-identification/language`

    this.http.post(url, body, { headers: this.authHeaders }).subscribe({
    next:response => {
      this.session.setUserLanguage(this.selectedLangLabel);
    },
    error:error => {
      console.error('Error en la solicitud:', error);
    }
  });

  }
}
