import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Country } from 'src/app/interfaces/country.interface';

@Component({
  selector: 'country-item',
  templateUrl: './country-item.component.html',
  styleUrls: ['./country-item.component.scss']
})
export class CountryItemComponent {

  @Input() selected: boolean = false;
  
  @Input() country!: Country;

  @Output() onSelect = new EventEmitter<Country>();

  select() {
    this.onSelect.emit(this.country);
  }

}
