<yoid-spinner *ngIf="loading && !operationData" />

<section *ngIf="!loading && operationData" class="page operation d-flex flex-column justify-content-between h-100">

    <section>

        <h2 class="opacity-50 fs-1 mb-4" translate>title.share_data_with</h2>

        <div class="fields d-flex gap-3 flex-column border p-4">

            <h3 class="d-block fs-1 fw-bold d-block mb-0">{{ operationData.serviceProviderData.name }}</h3>

            <!-- NAME -->
            <mat-form-field *ngIf="operationData.shareName" class="w-100 custom-disable-input">
                <img src="assets/img/icon/ic_user.svg" width="24" class="custom-disable-input" matPrefix alt="User icon" />
                <mat-label class="custom-disable-input" translate>header.name</mat-label>
                <input type="text" class="custom-disable-input" [placeholder]="'header.name' | translate" [value]="operationData.name" matInput disabled>
            </mat-form-field>

            <!-- ID DOC -->
            <mat-form-field *ngIf="operationData.shareIdentityDocument" class="w-100">
                <img src="assets/img/icon/ic_identity.svg" width="24" class="custom-disable-input" matPrefix alt="Identity document icon"/>
                <mat-label class="custom-disable-input">{{ operationData.identityDocumentType }}</mat-label>
                <input type="text" class="custom-disable-input" [value]="operationData.identityDocument" matInput disabled>
            </mat-form-field>

            <!-- PHONE NUMBER -->
            <mat-form-field *ngIf="operationData.sharePhoneNumber" class="w-100">
                <img src="assets/img/icon/ic_phone.svg" width="24" class="custom-disable-input" matPrefix alt="Phone icon" />
                <mat-label class="custom-disable-input" translate>header.phone_number</mat-label>
                <input type="text" class="custom-disable-input" [placeholder]="'header.phone_number' | translate" [value]="operationData.phoneNumber" matInput disabled>
            </mat-form-field>

            <!-- MULTIPLE BANK ACCOUNTS -->
            <mat-form-field *ngIf="operationData.shareBankAccount && operationData.bankAccounts && operationData.bankAccounts.length > 1" appearance="fill" class="w-100">
                <img src="assets/img/icon/ic_credit_card.svg" width="24" matPrefix alt="Bank accounts icon" />
                <mat-select [(ngModel)]="account" [placeholder]="'actions.select_account' | translate">
                    <mat-select-trigger *ngIf="selectedAccount">
                        <span [class]="account === -1 ? 'text-muted' : ''">
                            {{ selectedAccount.name ? selectedAccount.name : ("header.default_account_name" | translate) }}
                        </span>
                        <div style="font-size: 0.85em;" class="text-muted">{{ selectedAccount.iban }}</div>
                    </mat-select-trigger>
                    <mat-option [value]="-1" hidden>
                        <span>{{ operationData.bankAccounts![0].bank }}</span>
                        <div style="font-size: 0.85em;" class="text-muted" translate>actions.select_account</div>
                    </mat-option>
                    <mat-option *ngFor="let account of operationData.bankAccounts" [value]="account.id">
                        <span translate>{{ account.name ? account.name : "header.default_account_name" }}</span>
                        <div style="font-size: 0.85em;" class="text-muted">{{ account.iban }}</div>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- SINGLE BANK ACCOUNT -->
            <mat-form-field *ngIf="operationData.shareBankAccount && operationData.bankAccounts && operationData.bankAccounts.length === 1" class="w-100">
                <img src="assets/img/icon/ic_credit_card.svg" width="24" class="custom-disable-input" matPrefix alt="Bank account icon" />
                <mat-label class="custom-disable-input" translate>{{ operationData.bankAccounts[0].name }}</mat-label>
                <input type="text" class="custom-disable-input" [placeholder]="'header.bank_account' | translate" [value]="operationData.bankAccounts[0].iban"
                    matInput disabled>
            </mat-form-field>
            

        </div>

    </section>

    <section>

        <div class="d-flex justicy-content-between gap-lg-4 gap-2">
            <button mat-stroked-button [disabled]="loading" (click)="submit('reject')" color="primary" class="w-50">{{ "actions.reject" | translate }}</button>
            <button mat-raised-button [disabled]="operationData.shareBankAccount && (account === undefined || account === -1)" color="primary"
                (click)="submit('accept')" class="w-50">{{ "actions.accept" | translate }}
            </button>
        </div>

        <div style="margin-top: 10px;">
            <powered-by />
        </div>

    </section>


</section>