import {Component, OnInit} from '@angular/core';
import {OperationService} from '../../../services/operation.service';
import {TranslateService} from '@ngx-translate/core';
import {Constants} from '../../../shared/constants.shared';
import {SessionService} from 'src/app/services/session.service';
import {IdentificationRes} from 'src/app/interfaces/responses/identification-res.interface';
import {GlobalUtils} from 'src/app/utils/global.utils';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-identification',
  templateUrl: './identification.component.html'
})
export class IdentificationComponent implements OnInit {

  account?: number = -1;

  loading: boolean = true;

  operationData?: IdentificationRes;

  //userLanguage
  options: { value: string, label: string }[] = [];
  languageCode:string |undefined;
  datos: { [key: string]: string } = {};

  get selectedAccount() {
    if (!this.operationData || !this.operationData.bankAccounts) return undefined;
    if (this.account === -1) {
      return {
        name: this.operationData.bankAccounts[0].bank,
        iban: this.translate.instant("actions.select_account")
      };
    }
    return this.operationData.bankAccounts.filter(a => a.id === this.account)[0];
  }

  constructor(
    private session: SessionService,
    private operation: OperationService,
    private translate: TranslateService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.fetch(this.session.operationId!);
    this.loadSessionLanguage();
  }

  fetch(operationId: string) {
    this.loading = true;
    this.operation.identification(operationId).subscribe({
      next: res => {
        this.operationData = res;
        GlobalUtils.loadCss(document, res.serviceProviderData.styleUrl);
      },
      error: _ => this.session.error = Constants.ErrorTemplate.ERROR_GENERIC
    }).add(() => {
      if(this.operationData?.bankAccounts?.length === 1){
        this.account = this.operationData?.bankAccounts[0].id;
      }
      this.loading = false;
    });
  }

  submit(action: "accept" | "reject") {
    if (this.session.operationId && this.account) {
      this.loading = true;
      this.operation.submitIdentification(action, this.session.operationId, this.account).subscribe({
        next: res => window.location.href = res.urlRedirect,
        error: _ => this.session.error = Constants.ErrorTemplate.ERROR_GENERIC
      }).add(() => this.loading = false);
    }
  }


  loadSessionLanguage() {
    this.session.$userLanguage.subscribe(language=>{
      this.http.get('assets/i18n/locale.properties', { responseType: 'text' }).subscribe({
        next:(data: string) => {
          this.datos = this.parseProperties(data);
          this.languageCode = this.obtenerClave(language) || 'es-ES';
          this.translate.use(this.languageCode);
        },
        error:(error) => {
          console.error('Error al cargar las propiedades', error);
        }
      });
    });
  }

    private obtenerClave(valor: string): string | undefined {
      return Object.keys(this.datos).find(clave => this.datos[clave] === valor.toUpperCase());
    }

    private parseProperties(data: string): { [key: string]: string } {
      return data
        .split('\n')
        .filter(line => line.trim() !== '')
        .reduce((acc, line) => {
          const [clave, valor] = line.split('=');
          if (clave && valor) {
            acc[clave.trim()] = valor.trim();
          }
          return acc;
        }, {} as { [key: string]: string });
    }
}
