<section style="height: 100%; overflow-y: hidden !important;">

    <mat-dialog-actions mat-dialog-title class="border-bottom">
        <div class="d-flex justify-content-between align-items-center w-100">
            <span class="d-block fs-2 fw-bold">Documento a firmar</span>
            <button mat-fab (click)="close()">
                <mat-icon class="text-black">close</mat-icon>
            </button>
        </div>
    </mat-dialog-actions>
    
    <mat-dialog-content *ngIf="document">
      
        <pdf-viewer
            [src]="document"
            [page]="page"
            [render-text]="true"
            [show-all]="true"
            [fit-to-page]="true"
            [autoresize]="true"
            [show-borders]="false"
            (pageChange)="change($event)"
            class="pdf-viewer"></pdf-viewer>
    
    </mat-dialog-content>

</section>
