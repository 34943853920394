export class GlobalUtils {

    public static loadCss(document: Document, styleName: string) {

        const head = document.getElementsByTagName("head")[0];

        let themeLink = document.getElementById("client-theme") as HTMLLinkElement;

        if (themeLink) {
          themeLink.href = styleName;
        } else {
          const style = document.createElement("link");
          style.id = "client-theme";
          style.rel = "stylesheet";
          style.href = `${styleName}` + this.getStylesVersion();
          head.appendChild(style);
        }
    }

    public static loadFavicon(url: string) {

      const head = document.getElementsByTagName("head")[0];

      const favicon = document.createElement("link");
      favicon.rel = "shortcut icon";
      favicon.href = `${url}`;

      head.appendChild(favicon);
    }

    public static setTabTitle(title: string) {

      const head = document.getElementsByTagName("head")[0];

      const titleEl = head.getElementsByTagName("title")[0];
      titleEl.innerHTML = title;
    }

    public static getStylesVersion(): string {
      return "?v=" + new Date().getTime();
    }
}
