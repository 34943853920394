<section class="page d-flex flex-column justify-content-between h-100">

    <form>

        <button matSuffix mat-icon-button (click)="back()" class="mb-3">
            <mat-icon>arrow_back</mat-icon>
        </button>

        <h2 translate>title.select_your_bank</h2>

        <mat-form-field class="w-100">
            <mat-icon matPrefix>search</mat-icon>
            <input [(ngModel)]="search" name="search" matInput type="text" [placeholder]="'actions.search' | translate"
                autocomplete="off">
            <button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="search=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <div *ngIf="banks" class="border rounded p-4 mb-5" style="overflow-y: scroll; height: 44vh;">

            <mat-list-item *ngIf="!search" class="px-0">
                <span class="list-title" translate>title.most_used_banks</span>
            </mat-list-item>

            <mat-list *ngIf="!search" role="list" class="p-0">
                <mat-list-item *ngFor="let bank of mostUsedBanks; let i = index" (click)="select(bank)"
                    [class]="((i !== mostUsedBanks.length - 1) ? 'border-bottom' : '') + ' px-0'" matRipple>
                    <div class="d-flex justify-content-between">
                        <span class="d-block text-truncate">{{ bank.name }}</span>
                        <mat-icon color="primary">chevron_right</mat-icon>
                    </div>
                </mat-list-item>
            </mat-list>

            <mat-list-item *ngIf="!search" class="px-0 pt-3">
                <span class="list-title" translate>title.az</span>
            </mat-list-item>

            <mat-list role="list" class="p-0">
                <mat-list-item *ngFor="let bank of filteredBanks; let i = index" (click)="select(bank)"
                    [class]="((i !== filteredBanks.length - 1) ? 'border-bottom' : '') + ' px-0'" matRipple>
                    <div class="d-flex justify-content-between">
                        <span class="d-block text-truncate">{{ bank.name }}</span>
                        <mat-icon color="primary">chevron_right</mat-icon>
                    </div>
                </mat-list-item>
            </mat-list>

        </div>

    </form>

    <powered-by />
    
</section>