import { Language } from '../language.interface';

export interface StatusRes {
    redirect_url: string,
    status: string,
}

export enum Status {
    Active = 'accepted',
    Rejected = 'rejected',
    Expired = 'expired',
    Pending = 'pending_by_app',
}




