import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IdentificationRes } from '../interfaces/responses/identification-res.interface';
import { BankIdentificationRedirectRes } from '../interfaces/responses/bank-identification-redirect-res.interface';
import { SignatureRes } from '../interfaces/responses/signature-res.interface';
import { of, delay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  baseUrl: string = environment.apiBaseUrl;
  signatureBaseUrl: string = "/operation/signature";
  identificationBaseUrl: string = "/operation/bank-identification";

  constructor(
    private http: HttpClient
  ) { }

  submitIdentification(action: "accept" | "reject", operationId: string, bankId: number) {

    const headers = new HttpHeaders({
      "Accept-Language": "es",
      "operation-id": operationId
    });

    const body = (action === "accept" && (bankId !== -1)) ? { "bankAccountId": bankId } : {};

    const url = `${this.baseUrl}${this.identificationBaseUrl}/${action}`;

    return this.http.post<BankIdentificationRedirectRes>(url, body, { headers });

  }

  submitSignature(action: "accept" | "reject", operationId: string) {}

  identification(operationId: string) {

    const headers = new HttpHeaders({
      "Accept-Language": "es",
      "operation-id": operationId
    });

    const url = `${this.baseUrl}${this.identificationBaseUrl}`;

    return this.http.get<IdentificationRes>(url, { headers });

  }

  signature(operationId: string) {

    // const headers = new HttpHeaders({
    //   "Accept-Language": "es",
    //   "operation-id": operationId
    // });

    // const url = `${this.baseUrl}${this.signatureBaseUrl}`;

    // return this.http.get<SignatureRes>(url, { headers });

    const data: SignatureRes = {
      serviceProviderData: {
        name: "El Corte Inglés",
        styleUrl: "https://yoid-service-providers-dev.s3.eu-west-1.amazonaws.com/0b17a630-aa21-4b84-a6c8-9aa3a4c2bf61/styles.css",
        logoUrl: "https://yoid-service-providers-dev.s3.eu-west-1.amazonaws.com/aa7651d1-bf1b-4b79-95b2-04eaa9d5545a/logo.svg",
        faviconUrl: "https://yoid-service-providers-dev.s3.eu-west-1.amazonaws.com/aa7651d1-bf1b-4b79-95b2-04eaa9d5545a/logo.svg",
      },
      shareName: true,
      name: "Nombre Apellido1 Apellido2",
      shareIdentityDocument: true,
      identityDocumentType: "DNI",
      identityDocument: "48966215M",
      sharePhoneNumber: true,
      phoneNumber: "+34 658999105",
      shareDocumentName: true,
      documentName: "Contrato"
    }

    return of(data).pipe(delay(500));

  }

}
